<template>
  <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 7.5V4.5C8 3.672 8.672 3.75 9.5 3.75H11V0H8C5.514 0 3.5 2.014 3.5 4.5V7.5H0.5V11.25H3.5V19.5H8V11.25H10.25L11.75 7.5H8Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'IconFacebook'
}
</script>

