<template>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-322.000000, -1536.000000)" fill="#000000" fill-rule="nonzero">
                <g transform="translate(210.000000, 1447.000000)">
                    <g transform="translate(0.000000, 82.000000)">
                        <g transform="translate(16.000000, 0.000000)">
                            <g transform="translate(96.000000, 7.000000)">
                                <path d="M14.25,6 L12,6 L12,4.5 C12,2.019 9.981,0 7.5,0 C5.019,0 3,2.019 3,4.5 L3,6 L0.75,6 C0.336,6 0,6.336 0,6.75 L0,21 C0,22.6545 1.3455,24 3,24 L8.25,24 C8.664,24 9,23.664 9,23.25 C9,22.836 8.664,22.5 8.25,22.5 L3,22.5 C2.172,22.5 1.5,21.8265 1.5,21 L1.5,7.5 L3,7.5 L3,8.25 C3,8.664 3.336,9 3.75,9 C4.164,9 4.5,8.664 4.5,8.25 L4.5,7.5 L10.5,7.5 L10.5,8.25 C10.5,8.664 10.836,9 11.25,9 C11.664,9 12,8.664 12,8.25 L12,7.5 L13.5,7.5 L13.5,8.25 C13.5,8.664 13.836,9 14.25,9 C14.664,9 15,8.664 15,8.25 L15,6.75 C15,6.336 14.664,6 14.25,6 Z M10.5,6 L4.5,6 L4.5,4.5 C4.5,2.8455 5.8455,1.5 7.5,1.5 C9.1545,1.5 10.5,2.8455 10.5,4.5 L10.5,6 Z" id="Shape"></path>
                                <path d="M23.25,12 L20.9235,12 C20.5755,10.29 19.0605,9 17.25,9 C15.4395,9 13.9245,10.29 13.5765,12 L11.25,12 C10.836,12 10.5,12.336 10.5,12.75 L10.5,21 C10.5,22.6545 11.8455,24 13.5,24 L21,24 C22.6545,24 24,22.6545 24,21 L24,12.75 C24,12.336 23.664,12 23.25,12 Z M17.25,10.5 C18.2265,10.5 19.0515,11.1285 19.362,12 L15.138,12 C15.4485,11.1285 16.2735,10.5 17.25,10.5 Z M22.5,21 C22.5,21.8265 21.828,22.5 21,22.5 L13.5,22.5 C12.672,22.5 12,21.8265 12,21 L12,13.5 L13.5,13.5 L13.5,14.25 C13.5,14.664 13.836,15 14.25,15 C14.664,15 15,14.664 15,14.25 L15,13.5 L19.5,13.5 L19.5,14.25 C19.5,14.664 19.836,15 20.25,15 C20.664,15 21,14.664 21,14.25 L21,13.5 L22.5,13.5 L22.5,21 Z" id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
  name: 'IconBag'
}
</script>

