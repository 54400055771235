<template>
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.75 1.91667C0.75 1.27233 1.27233 0.75 1.91667 0.75H3.82956C4.08064 0.75 4.30356 0.910668 4.38296 1.14887L5.25668 3.77004C5.34848 4.04544 5.2238 4.34643 4.96416 4.47626L3.64742 5.13462C4.2904 6.56071 5.43929 7.7096 6.86538 8.35258L7.52374 7.03584C7.65357 6.77619 7.95456 6.65152 8.22996 6.74332L10.8511 7.61704C11.0893 7.69644 11.25 7.91936 11.25 8.17044V10.0833C11.25 10.7277 10.7277 11.25 10.0833 11.25H9.5C4.66751 11.25 0.75 7.33249 0.75 2.5V1.91667Z" stroke="#DD4C27" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
  name: 'IconPhone'
}
</script>

