<template>
    <svg width="19px" height="12px" viewBox="0 0 19 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-995.000000, -647.000000)" fill="#000000" fill-rule="nonzero">
                <g transform="translate(210.000000, 264.000000)">
                    <g transform="translate(516.000000, 366.000000)">
                        <g transform="translate(246.000000, 0.000000)">
                            <g transform="translate(23.000000, 17.000000)">
                                <path d="M18.8688224,6.16790408 C19.0471898,5.92361837 19.0394347,5.59014898 18.8494347,5.35361837 C15.9761694,1.82116939 12.8741286,0.0297408163 9.62474082,0.0297408163 C4.11474082,0.0297408163 0.287597959,5.16361837 0.128618367,5.38076122 C-0.0497489796,5.62504694 -0.0419938776,5.95851633 0.148006122,6.19504694 C3.01739388,9.73137347 6.11943469,11.522802 9.36882245,11.522802 C14.8788224,11.522802 18.7059653,6.38892449 18.8688224,6.16790408 Z M9.36882245,10.192802 C6.66229184,10.192802 4.02555714,8.70382245 1.52065918,5.77239388 C2.50167959,4.61300612 5.61923061,1.35974082 9.62474082,1.35974082 C12.3312714,1.35974082 14.9680061,2.84872041 17.4729041,5.78014898 C16.4918837,6.93953673 13.3743327,10.192802 9.36882245,10.192802 Z" id="Shape"></path>
                                <path d="M9.49678163,2.39892449 C7.63555714,2.39892449 6.11943469,3.91504694 6.11943469,5.77627143 C6.11943469,7.63749592 7.63555714,9.15361837 9.49678163,9.15361837 C11.3580061,9.15361837 12.8741286,7.63749592 12.8741286,5.77627143 C12.8741286,3.91504694 11.3580061,2.39892449 9.49678163,2.39892449 Z M9.49678163,7.82361837 C8.36841429,7.82361837 7.44943469,6.90463878 7.44943469,5.77627143 C7.44943469,4.64790408 8.36841429,3.72892449 9.49678163,3.72892449 C10.625149,3.72892449 11.5441286,4.64790408 11.5441286,5.77627143 C11.5441286,6.90463878 10.625149,7.82361837 9.49678163,7.82361837 Z" id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
  name: 'IconEye'
}
</script>

