<template>
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g transform="translate(-879.000000, -644.000000)" fill="#000000" fill-rule="nonzero">
            <g transform="translate(210.000000, 264.000000)">
                <g transform="translate(516.000000, 366.000000)">
                    <g transform="translate(127.000000, 0.000000)">
                        <g transform="translate(26.000000, 14.000000)">
                            <path d="M10.3132903,7.45187097 L7.09264516,5.11070968 C6.97445161,5.02503226 6.81754839,5.01212903 6.688,5.07870968 C6.55741935,5.14477419 6.47587097,5.27896774 6.47587097,5.424 L6.47587097,10.1047742 C6.47587097,10.2513548 6.55741935,10.3850323 6.688,10.4510968 C6.74322581,10.4789677 6.8036129,10.4929032 6.86451613,10.4929032 C6.944,10.4929032 7.02451613,10.4676129 7.09264516,10.4175484 L10.3132903,8.07845161 C10.4149677,8.0036129 10.4743258,7.888 10.4743258,7.76516129 C10.4748387,7.64025806 10.4139355,7.52516129 10.3132903,7.45187097 Z" id="Path"></path>
                            <path d="M8.00026621,0.00103225806 C3.58111211,0.00103225806 0,3.5826211 0,8.00236351 C0,12.4205084 3.58111211,16.0010323 8.00026621,16.0010323 C12.4183555,16.0010323 16,12.4199759 16,8.00236351 C16.0005324,3.5826211 12.4183555,0.00103225806 8.00026621,0.00103225806 Z M8.00026621,14.6660571 C4.32012246,14.6660571 1.3363948,11.6835297 1.3363948,8.00236351 C1.3363948,4.32279483 4.32012246,1.33653996 8.00026621,1.33653996 C11.6798775,1.33653996 14.6630728,4.32226233 14.6630728,8.00236351 C14.6636052,11.6835297 11.6798775,14.6660571 8.00026621,14.6660571 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
</template>

<script>
export default {
  name: 'IconPlay'
}
</script>

