<template>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g fill="#333333">
                <path d="M21.3576,4.01916 L21.3576,3.24192 C21.3576,1.4543 19.9052,0 18.12,0 L5.87194,0 C4.08675,0 2.63438,1.4543 2.63438,3.24192 L2.63438,4.02094 C1.13053,4.33153 0,5.6123 0,7.14122 L0,8.61253 C0,9.00084 0.314813,9.31566 0.703125,9.31566 L2.63438,9.31566 L2.63438,20.1488 C2.63438,20.4858 2.87152,20.767 3.18788,20.8357 L3.18788,23.2969 C3.18788,23.6852 3.50269,24 3.891,24 L7.60744,24 C7.99575,24 8.31056,23.6852 8.31056,23.2969 L8.31056,20.852 L15.6814,20.852 L15.6814,23.2969 C15.6814,23.6852 15.9962,24 16.3845,24 L20.1009,24 C20.4892,24 20.804,23.6852 20.804,23.2969 L20.804,20.8357 C21.1204,20.767 21.3576,20.4858 21.3576,20.1488 L21.3576,9.31566 L23.2969,9.31566 C23.6852,9.31566 24,9.00084 24,8.61253 L24,7.14122 C24,5.60953 22.8655,4.3268 21.3576,4.01916 Z M4.04102,19.4466 L4.04102,15.2344 L19.9517,15.2344 L19.9517,19.4466 L4.04102,19.4466 Z M18.4774,13.8273 L19.9517,13.8273 L19.9517,4.74414 L4.04102,4.74414 L4.04102,13.8273 L11.5459,13.8273 C11.8703,12.2105 13.3008,10.9887 15.0117,10.9887 C16.7225,10.9887 18.1531,12.2105 18.4774,13.8273 Z M17.0223,13.8268 L13,13.8268 C13.2892,12.9942 14.0812,12.3945 15.0111,12.3945 C15.9411,12.3946 16.7331,12.9942 17.0223,13.8268 Z M18.1204,1.40625 L5.87233,1.40625 C4.86255,1.40625 4.04102,2.2297 4.04102,3.24192 L4.04102,3.33787 L19.9517,3.33787 L19.9517,3.24192 C19.9517,2.2297 19.1302,1.40625 18.1204,1.40625 Z M1.40625,7.90941 L1.40625,7.14122 C1.40625,6.38625 1.91616,5.73966 2.63438,5.47852 L2.63438,7.90941 L1.40625,7.90941 Z M4.59375,22.5933 L6.90394,22.5933 L6.90394,20.8516 L4.59375,20.8516 L4.59375,22.5933 Z M17.0879,22.5933 L17.0879,20.8516 L19.398,20.8516 L19.398,22.5933 L17.0879,22.5933 Z M21.3574,7.90845 L22.5936,7.90845 L22.5936,7.14027 C22.5936,6.38248 22.0799,5.73383 21.3574,5.47461 L21.3574,7.90845 Z" id="Shape"></path>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
  name: 'IconBus'
}
</script>

