<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.33331 11.3346L1.33331 12.168C1.33331 13.5487 2.4526 14.668 3.83331 14.668L12.1666 14.668C13.5474 14.668 14.6666 13.5487 14.6666 12.168L14.6666 11.3346M11.3333 4.66797L7.99998 1.33464M7.99998 1.33464L4.66665 4.66797M7.99998 1.33464L7.99998 11.3346" stroke="#383838" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>
export default {
  name: 'IconShare'
}
</script>

