<template>
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-583.000000, -1535.000000)" fill="#000000" fill-rule="nonzero">
                <g transform="translate(210.000000, 1447.000000)">
                    <g transform="translate(0.000000, 82.000000)">
                        <g transform="translate(16.000000, 0.000000)">
                            <g id="icon-money" transform="translate(357.000000, 6.000000)">
                                <path d="M20.4755625,3.5244375 C18.2028281,1.25165625 15.1927969,0 12,0 C8.80720313,0 5.79717188,1.25165625 3.5244375,3.5244375 C1.25165625,5.79717188 0,8.80720313 0,12 C0,15.1927969 1.25165625,18.2028281 3.5244375,20.4755625 C5.79717188,22.7483438 8.80720313,24 12,24 C15.1927969,24 18.2028281,22.7483438 20.4755625,20.4755625 C22.7483438,18.2028281 24,15.1927969 24,12 C24,8.80720313 22.7483438,5.79717188 20.4755625,3.5244375 Z M12,22.59375 C6.15857812,22.59375 1.40625,17.8414219 1.40625,12 C1.40625,6.15857812 6.15857812,1.40625 12,1.40625 C17.8414219,1.40625 22.59375,6.15857812 22.59375,12 C22.59375,17.8414219 17.8414219,22.59375 12,22.59375 Z" id="Shape"></path>
                                <path d="M12,2.859375 C6.95985937,2.859375 2.859375,6.95985937 2.859375,12 C2.859375,17.0401406 6.95985937,21.140625 12,21.140625 C17.0401406,21.140625 21.140625,17.0401406 21.140625,12 C21.140625,6.95985937 17.0401406,2.859375 12,2.859375 Z M12,19.734375 C7.73526563,19.734375 4.265625,16.2647344 4.265625,12 C4.265625,7.73526563 7.73526563,4.265625 12,4.265625 C16.2647344,4.265625 19.734375,7.73526563 19.734375,12 C19.734375,16.2647344 16.2647344,19.734375 12,19.734375 Z" id="Shape"></path>
                                <path d="M14.109375,9.8835 C14.109375,10.2718125 14.4241875,10.586625 14.8125,10.586625 C15.2008125,10.586625 15.515625,10.2718125 15.515625,9.8835 C15.515625,9.1048125 15.112875,8.356125 14.4105938,7.8294375 C13.9309219,7.46967187 13.3407187,7.22934375 12.703125,7.12710937 L12.703125,6.36782812 C12.703125,5.97951562 12.3883125,5.66470312 12,5.66470312 C11.6116875,5.66470312 11.296875,5.97951562 11.296875,6.36782812 L11.296875,7.12710937 C10.6592344,7.22934375 10.0690313,7.46967187 9.58940625,7.8294375 C8.887125,8.356125 8.484375,9.1048125 8.484375,9.8835 C8.484375,10.6621875 8.887125,11.4108281 9.58940625,11.9375625 C10.0690781,12.2973281 10.6592813,12.5376562 11.296875,12.6398906 L11.296875,15.4246875 C10.486875,15.2267344 9.890625,14.7010781 9.890625,14.10225 C9.890625,13.7139375 9.5758125,13.399125 9.1875,13.399125 C8.7991875,13.399125 8.484375,13.7139375 8.484375,14.10225 C8.484375,14.8809375 8.887125,15.6295781 9.58940625,16.1563125 C10.0690781,16.5160781 10.6592813,16.7564063 11.296875,16.8586406 L11.296875,17.625 C11.296875,18.0133125 11.6116875,18.328125 12,18.328125 C12.3883125,18.328125 12.703125,18.0133125 12.703125,17.625 L12.703125,16.8586406 C13.3407656,16.7564063 13.9309688,16.5160781 14.4105938,16.1563125 C15.112875,15.629625 15.515625,14.8809375 15.515625,14.10225 C15.515625,13.3235625 15.112875,12.574875 14.4105938,12.0481875 C13.9309219,11.6884219 13.3407187,11.4480937 12.703125,11.3458594 L12.703125,8.5610625 C13.513125,8.7590625 14.109375,9.28467188 14.109375,9.8835 Z M9.890625,9.8835 C9.890625,9.28467187 10.486875,8.75901562 11.296875,8.5610625 L11.296875,11.2058906 C10.486875,11.0079844 9.890625,10.4823281 9.890625,9.8835 Z M14.109375,14.10225 C14.109375,14.7010781 13.513125,15.2267344 12.703125,15.4246875 L12.703125,12.7798594 C13.513125,12.9778125 14.109375,13.5034219 14.109375,14.10225 Z" id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
  name: 'IconMoney'
}
</script>

